import React, { Component } from 'react';
import { Result, Button } from 'antd';
export default class NotFound extends Component {
  /* 返回首页 */
  handleBackHome = () => {
    this.props.history.push('/index');
  };
  render() {
    return (
      <Result
        status="404"
        title="404"
        subTitle="抱歉，您访问的页面不存在。"
        extra={
          <Button type="primary" onClick={this.handleBackHome}>
            返回首页
          </Button>
        }
      />
    );
  }
}
