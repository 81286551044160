import React, { Component } from 'react';

class index extends Component {
  render() {
    return (
      <div className="pd20">
        <div class="main">
          <div class="mt10">更新日期：2023年4月17日 </div>
          <div class="mt3"> 生效日期：2021年7月12日</div>
          <div class="text-bold mt10">提示</div>
          <div class="text-bold mt10">
            本隐私政策适用于义乌海烨电子有限公司（以下简称我公司）提供的手机妈妈商盟的产品及服务。本隐私政策中手机妈妈商盟的客户端软件可能会涉及不同应用版本，用户需选择与所安装手机相匹配的软件版本，不同版本的手机妈妈商盟所提供的功能和服务可能有所不同。
          </div>
          <div class="mt10">
            我公司深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我公司致力于维持您对我公司的信任，恪守以下原则保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我公司承诺，我公司将按业界成熟的安全标准采取相应的安全保护措施来保护您的个人信息。
            为了更好的保护您的个人信息，建议您仔细阅读更新后的隐私政策。如您对本隐私政策条款有任何异议或疑问，您可通过本隐私政策第八条公布的联系方式与我公司联系。
          </div>
          <div class="mt10">
            我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（OAID/IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI
            信息）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。
		      </div>
          <div class="text-bold mt10">目录</div>
          <div class="mt10">一、我公司如何收集和使用您的个人信息</div>
          <div class="mt3">二、我公司如何使用Cookie和同类技术</div>
          <div class="mt3">三、我公司如何共享、转让、公开披露您的个人信息</div>
          <div class="mt3">四、我公司如何保护您的个人信息</div>
          <div class="mt3">五、您的权利</div>
          <div class="mt3">六、您的个人信息如何在全球范围转移</div>
          <div class="mt3">七、本隐私政策如何更新</div>
          <div class="mt3">八、如何联系我公司</div>
          <div class="mt3">九、争议解决</div>
          <div class="text-bold mt10">一、我公司如何收集和使用您的个人信息</div>
          <div class="mt10">1、个人信息和个人敏感信息定义</div>
          <div class="mt10">
            个人信息，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我公司实际具体收集的个人信息种类以下文描述为准。
          </div>
          <div class="mt3">
            个人敏感信息，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。我公司实际具体收集的个人敏感信息种类以下文描述为准。
          </div>
          <div class="mt10">2、我公司如何收集和使用您的个人信息</div>
          <div class="mt10">（1）登录手机妈妈商盟</div>
          <div class="mt10">
            您登录手机妈妈商盟时，您需提供您的手机号及短信验证码。如果不登录，并不会影响您使用手机妈妈商盟搜索浏览功能，但会影响您下单等附加业务功能。
          </div>
          <div class="mt10">（2）实名认证</div>
          <div class="mt10">
            获取用户<span class="fwred16">身份证正反面照片</span>信息，用于核对用户的信息的真实性，审核判断用户是否达到成年的标准，不实名认证，并不会影响您使用手机妈妈商盟搜索浏览等功能。
          </div>   
          <div class="mt10">（3）读取联系人信息</div>
          <div class="mt10">
            获取该功能，用于进行软件<span class="fwred16">读取联系人信息</span>，用于多设个设备上同步处理短信和通话记录，如不获取，并不会影响您使用手机妈妈商盟其他功能。
          </div>
          <div class="mt10">（4）<span class="fwred16">读取通话记录 权限</span></div>
          <div class="mt10">
            获取该权限，用于进行软件通话记录，用于多设个设备上同步处理短信和通话记录，如不授权，并不会影响您使用手机妈妈商盟其他功能。
          </div>
          <div class="mt10">（5）<span class="fwred16">访问相机 权限</span></div>
          <div class="mt10">
            获取该权限，用于拍照上传一些图片，视频，如不授权，并不会影响您使用手机妈妈商盟其他功能。
          </div>
          <div class="mt10">（6）<span class="fwred16">读取通话状态和移动网络信息 权限</span></div>
          <div class="mt10">
            获取该权限，用于监听用户的网络状态，方便提示用户，网络情况，增加用户体验，如不授权，并不会影响您使用手机妈妈商盟其他功能。
          </div>
          <div class="mt10">（7）获取您的<span class="fwred16">设备唯一识别码</span>（OAID/IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI）,软件列表等信息</div>
          <div class="mt10">
            获取该信息，以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。如不授权，并不会影响您使用手机妈妈其他功能。
          </div>
          <div class="mt10">
            3、您理解并同意，以下情形中，我公司收集、使用您的个人信息不必另行征得您的授权同意：
          </div>
          <div class="mt10">(1) 我公司履行法律法规规定的义务相关的；</div>
          <div class="mt3">(2)与国家安全、国防安全直接相关的；</div>
          <div class="mt3">
            (3)与公共安全、公共卫生、重大公共利益直接相关的；
          </div>
          <div class="mt3">
            (4)与刑事侦查、起诉、审判和判决执行等直接相关的；
          </div>
          <div class="mt3">
            (5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您授权同意的；
          </div>
          <div class="mt3">(6) 所涉及的您的信息是您自行向社会公众公开的；</div>
          <div class="mt3">(7)根据您要求签订和履行合同所必需的； </div>
          <div class="mt3">
            (8)从合法公开披露的信息中收集您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
          </div>
          <div class="mt3">
            (9)维护所提供我公司手机妈妈商盟产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
          </div>
          <div class="mt3"> (10）法律法规等规定的其他情形。</div>
          <div class="mt10">4、为您提供的其他附加服务</div>
          <div class="mt10">
            (1)
            基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫码用于购物下单、拍摄照片或上传视频与客服沟通提供证明。请您知晓，即使您已同意开启相机/摄像头权限，我们也仅会在您主动拍摄、录制、扫码等使用相机/摄像头时获取信息。
          </div>
          <div class="mt3">
            (2)
            基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频，进行购物下单、拍摄照片或上传视频与客服沟通提供证明。
          </div>
          <div class="mt3">
            (3)
            基于通讯录的附加服务：开启通讯录功能，为了高效率的通过实名认证的审核，请您知晓，我们只会在您进行实名认证的时候才会要求使用开启通讯录权限
          </div>
          <div class="text-bold mt10"> 二、我公司如何使用Cookie和同类技术</div>
          <div class="mt10">
            1、Cookie：为确保手机妈妈商盟正常运转，我公司会在您的移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。我公司不会将Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。
          </div>
          <div class="mt10">
            2、SDK：SDK是我公司嵌入手机妈妈商盟的一组工具和/或代码，允许第三方授权合作伙伴收集有关用户如何与服务交互的信息；
          </div>
          <div class="text-bold mt10">
            三、我公司如何共享、转让、公开披露您的个人信息
          </div>
          <div class="mt10"> 1、共享</div>
          <div class="mt10">
            我公司不会与我公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
          </div>
          <div class="mt10">（1）事先已获得您的明确授权或同意；</div>
          <div class="mt3">
            （2）根据适用的法律法规、法律程序、政府的强制命令或司法裁定而需共享您的个人信息；
          </div>
          <div class="mt3">
            （3）在法律要求或允许的范围内，为了保护手机妈妈商盟及其用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方；
          </div>
          <div class="mt3">
            （4）您的个人信息可能会在我公司关联公司之间共享。我公司只会共享必要的个人信息，且这种共享亦受本隐私政策声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；
          </div>
          <div class="mt3">
            （5）为实现本隐私政策中声明的目的，我公司的某些服务将由授权合作伙伴提供，但不提供信息推广和广告投放等服务
          </div>
          <div class="mt3">
          ①第三方SDK类服务商：我公司的产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。目前我公司接入的第三方SKD如下：
          </div>
          <div class="">（1）阿里weexSDK</div>
          <div class="">SDK 包名：com.taobao</div>
          <div class="">
            场景描述：uni-app基础模块默认集成，用于渲染uniapp的nvue页面引擎
          </div>
          <div class="">个人信息字段：存储的个人文件</div>
          <div class="">官网链接：http://doc.weex.io/zh</div>
          <div class="">第三方SDK公司名称：阿里巴巴集团控股有限公司</div>
          <div class="">（2）App 支付宝客户端 SDK</div>
          <div class="">SDK 包名：com.alipay</div>
          <div class="">场景描述：用于支付</div>
          <div class="">个人信息字段：Android ID、OAID、AAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP 地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 参数、Wi-Fi 列表</div>
          <div class="">
            SDK隐私政策链接：https://render.alipay.com/p/yuyan/180020010001196791/prediv.html?agreementId=AG00000132
          </div>
          <div class="">第三方SDK公司名称：支付宝（中国）网络技术有限公司</div>
          
          <div class="mt10">2、转让</div>
          <div class="mt10">
            我公司不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </div>
          <div class="mt10">
            （1）获得您的明确同意后，我公司会向其他方转让您的个人信息；
          </div>
          <div class="mt3">
            （2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我公司会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我公司将要求该公司、组织重新向您征求授权同意。
          </div>
          <div class="mt10">
            3、公开披露 我公司仅会在以下情况下，公开披露您的个人信息：
          </div>
          <div class="mt10">（1）获得您明确同意后；</div>
          <div class="mt3">
            （2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我公司可能会公开披露您的个人信息。
          </div>
          <div class="text-bold mt10">四、我公司如何保护您的个人信息</div>
          <div class="mt10">
            1、我公司已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我公司会采取一切合理可行的措施，保护您的个人信息。例如我公司使用加密技术确保数据的保密性；我公司会使用受信赖的保护机制防止数据遭到恶意攻击；我公司会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我公司会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
          </div>
          <div class="mt10">
            2、我公司在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内，不会进行任何数据出境。您的个人信息存储期限为10年；10年期满后如您继续在使用手机妈妈商盟服务的，该等服务必需的个人信息的存储期限再续10年，如届时您已经不再使用手机妈妈商盟服务的，我公司将及时删除您的个人信息。
          </div>
          <div class="mt10">
            3、尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我公司将尽力确保您提供给我们的个人信息的安全性。
          </div>
          <div class="mt10">
            您知悉并理解，您接入我公司的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人。
          </div>
          <div class="text-bold mt10">五、您的权利</div>
          <div class="mt10">
            按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我公司保障您对自己的个人信息行使以下权利：
          </div>
          <div class="mt10">1、访问您的个人信息</div>
          <div class="mt10">
            您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
          </div>
          <div class="mt10">（1）访手机号基本信息：</div>
          <div class="mt3">
            ①进入手机妈妈商盟后，点击“我的” ②登录的状态下页面上会展示手机号
          </div>
  
          <div class="mt10">2、删除您的个人信息</div>
          <div class="mt10">
            在以下情形中，您可以向我公司提出删除个人信息的请求：
          </div>
          <div class="mt10">
            （1）如果我公司处理个人信息的行为违反法律法规；
          </div>
          <div class="mt3">
            （2）如果我公司收集、使用您的个人信息，却未征得您的同意；
          </div>
          <div class="mt3">
            （3）如果我公司处理个人信息的行为违反了与您的约定；
          </div>
          <div class="mt3">
            （4）如果您不再使用我公司的产品或服务，或您注销了帐号；
          </div>
          <div class="mt3"> （5）如果我公司不再为您提供产品或服务。</div>
          <div class="mt10">
            若我公司决定响应您的删除请求，我公司还将同时通知从我公司获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
          </div>
          <div class="mt10">
            当您从我公司的服务中删除信息后，我公司可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          </div>
          <div class="mt10"> 3、撤回同意</div>
          <div class="mt10">
            您可以通过关闭设备功能改变您授权我公司继续收集个人信息的范围或撤回您的授权。您还可以通过注销帐户的方式，撤回我公司继续收集您个人信息的全部授权。
          </div>
          <div class="mt10">
            4、退出帐户 您随时可退出此前注册的帐户
          </div>
          <div class="mt10">点击我的，点击头像</div>
          <div class="mt10">
            在退出帐户之后，我公司将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
          </div>
          <div class="mt10">
            在以下情形中，按照法律法规要求，我公司将无法响应您的请求：
          </div>
          <div class="mt10">（1）与国家安全、国防安全直接相关的；</div>
          <div class="mt3">
            （2）与公共安全、公共卫生、重大公共利益直接相关的；
          </div>
          <div class="mt3">
            （3）与犯罪侦查、起诉、审判和判决执行等直接相关的；
          </div>
          <div class="mt3">（4）有充分证据表明您存在主观恶意或滥用权利的；</div>
          <div class="mt3">
            （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </div>
          <div class="mt3">（6）涉及商业秘密的。</div>
          <div class="mt10"> 5、注销账号</div>
          <div class="mt10">
            您随时可以注销此前注册的账号，在账户注销后，原账户相关信息会被清空且无法找回且无法进行以下操作：
          </div>
          <div class="mt10">（1）账号中的个人信息、历史信息等将被清空；</div>
          <div class="mt3">（2）账号中的余额、积分将被清空；</div>
          <div class="mt10">
            在以下情形中，按照法律法规要求，我公司可能无法响应您的请求：
          </div>
          <div class="mt10">（1）与国家安全、国防安全直接相关的；</div>
          <div class="mt3">
            （2）与公共安全、公共卫生、重大公共利益直接相关的；
          </div>
          <div class="mt3">
            （3）与犯罪侦查、起诉、审判和判决执行等执行相关的；
          </div>
          <div class="mt3">（4）有充分证据表明您存在主观恶意或滥用权力的；</div>
          <div class="mt10">
            （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </div>
          <div class="mt3">（6）涉及商业秘密的。 注销操作</div>
          <div class="mt10">
            点击“我的”，点击“关于我们”，点击“账号注销”，点击“确认注销”。
          </div>
          <div class="text-bold mt10">六、您的个人信息如何在全球范围转移</div>
          <div class="mt10">
            目前我公司不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我公司会向您告知信息出境的目的、接收方、安全保障措施和安全风险，并征得您的同意。
          </div>
          <div class="text-bold mt10">七、本隐私政策如何更新</div>
          <div class="mt10">
            我公司的隐私政策可能变更。未经您明确同意，我公司不会削减您按照本隐私政策所应享有的权利。
            我公司会在本页面上发布对本隐私政策所做的任何变更。对于重大变更，我公司还会提供更为显著的通知。本隐私政策所指的重大变更包括但不限于：
          </div>
          <div class="mt10">
            1、我公司的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </div>
          <div class="mt10">
            2、我公司在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          </div>
          <div class="mt10">
            3、个人信息共享、转让或公开披露的主要对象发生变化；
          </div>
          <div class="mt10">
            4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
          </div>
          <div class="mt10">
            5、我公司负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
          </div>
          <div class="mt10">6、个人信息安全影响评估报告表明存在高风险时。</div>
          <div class="mt10">我公司还会将本隐私政策的旧版本存档，供您查阅。</div>
          <div class="text-bold mt10">八、如何联系我公司</div>
          <div class="mt10">
            如您对本隐私政策有任何疑问、意见或建议，您可以通过如下方式与我公司联系。一般情况下，我公司将在15个工作日内回复您。
          </div>
          <div class="mt10">电子邮件：postmaster@aixzu.com</div>
          <div class="mt10">电 话：4008261610</div>
          <div class="text-bold mt10">九、争议解决</div>
          <div class="mt10">
            因本隐私政策产生的及与本隐私政策相关的任何争议，应提交本协议签订地（即金华市义乌市）有管辖权的法院诉讼解决。
          </div>
        </div>
      </div>
    );
  }
}

export default index;
