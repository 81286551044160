import React, { Component } from 'react';
import { Result, Button } from 'antd';

export default class NotAllowed extends Component {
  /* 返回首页 */
  handleBackHome = () => {
    this.props.history.push('/index');
  };
  render() {
    return (
      <Result
        status="403"
        title="403"
        subTitle="抱歉，您无权访问此页面。"
        extra={
          <Button type="primary" onClick={this.handleBackHome}>
            返回首页
          </Button>
        }
      />
    );
  }
}
