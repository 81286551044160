import Index from '../pages/index/index';
import Privacy from '../pages/privacy/index';
import PrivacySM from '../pages/privacySM/index';
import PrivacySJD from '../pages/privacySJD/index';
import NotFound from '../pages/notFound';
import NotAllowed from '../pages/notAllowed';
import UserAgreement from '../pages/userAgreement';
import UserAgreementSM from '../pages/userAgreementSM';
import UserAgreementSJD from '../pages/userAgreementSJD';
import NumberCard from '../pages/numberCard';

const routes = [{
  exact: true,
  path: '/',
  redirect: '/index'
},
{
  exact: true,
  path: '/index',
  component: Index
},
{
  path: '/privacy',
  component: Privacy
},
{
  path: '/privacySM',
  component: PrivacySM
},
{
  path: '/privacySJD',
  component: PrivacySJD
},
{
  path: '/userAgreement',
  component: UserAgreement
},
{
  path: '/userAgreementSM',
  component: UserAgreementSM
},
{
  path: '/userAgreementSJD',
  component: UserAgreementSJD
},
{
  path: '/numberCard',
  component: NumberCard
},
{
  path: '403',
  component: NotAllowed
},
{
  path: '*',
  component: NotFound
}
];

export default routes;