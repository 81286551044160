import React, { Component } from 'react';

class index extends Component {
  render() {
    return (
      <div className="pd20">
        <div class="text-bold mt10">重要提示：</div>
        <div class="text-bold mt10">
          请您仔细阅读以下条款，并确认您已完全理解本协议之规定，尤其是免除及限制责任的条款、知识产权条款、法律适用及争议解决条款。
        </div>
        <div class="mt10">
          如您对本声明或本协议任何条款有异议，请停止注册或使用手机妈妈（包括微信小程序、支付宝小程序、APP等手机端、电脑等设备客户端应用程序，下同）软件及所提供的全部服务。
        </div>
        <div class="text-bold mt10">一、序言</div>
        <div class="mt10">
          1、您使用手机妈妈软件和/或服务，即视为您签署了本协议，表明您自愿接受本协议全部条款的约束，本协议将构成您与义乌海烨电子有限公司（以下称我公司）就“手机妈妈”软件及服务（以下统称“手机妈妈”）之间具有约束力的法律文件。无论您是进入手机妈妈浏览网页，还是在手机妈妈上发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用手机妈妈的行为，都将被视作已无条件接受本声明所涉全部内容。
        </div>
        <div class="mt10">
          2、我公司有权利对本协议进行修改，我公司将在手机妈妈相关页面公告或发送通知等方式公布修改的内容，修改后的协议一经公布即有效的代替原协议。如果您不同意本协议的修改，请立即停止访问或使用手机妈妈或取消已经获得的服务；如果您选择继续访问或使用手机妈妈，则视为您已接受本协议的修改。
        </div>
        <div class="mt10">
          3、本协议所列明的条款，并不能完全涵盖您与我公司之间所有的权利和义务。因此，我公司不定期公布的其他声明、规则、子协议等均视为本协议之补充协议，为本协议不可分割的组成部分，与本协议具有同等法律效力。
        </div>
        <div class="mt10">
          4、如本协议与手机妈妈的补充协议不一致，以补充协议内容为准。
        </div>
        <div class="text-bold mt10">二、帐号密码与注册、登录</div>
        <div class="mt10">1、帐号的取得</div>
        <div class="mt10">
          (1)您确认，在您开始使用手机妈妈时，已经具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
        </div>
        <div class="mt10">
          (2)您注册成功后，即成为手机妈妈注册用户，用户须对在手机妈妈的注册信息的真实性、合法性、有效性承担全部责任。您可自行创建、修改昵称，但用户名和昵称的命名及使用应遵守相关法律法规并符合网络道德，不得冒充他人或恶意注册使人误认；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；用户名和昵称中不能含有任何侮辱、诽谤、淫秽或暴力等侵害他人合法权益或违反公序良俗的词语。如您违反前述规定，手机妈妈有权随时限制或拒绝您使用该账号，甚至注销该账号。
        </div>
        <div class="mt10">(3)用户名的管理</div>
        <div class="mt10">
          1)请勿以党和国家领导人或其他名人的真实姓名、字、号、艺名、笔名、头衔等注册和使用昵称（如确为本人，需要提交相关证据并通过审核方可允许使用）；
        </div>
        <div class="mt3">
          2)请勿以国家组织机构或其他组织机构的名称等注册和使用昵称（如确为该机构，需要提交相关证据并通过审核方可允许使用）；
        </div>
        <div class="mt3">
          3)请勿注册和使用与其他网友相同、相仿的用户名或昵称；
        </div>
        <div class="mt3">4)请勿注册和使用不文明、不健康的用户名和昵称；</div>
        <div class="mt3">
          5)请勿注册和使用易产生歧义、引起他人误解或带有各种奇形怪状符号的用户名和昵称。
        </div>
        <div class="mt3">
          6)用户以虚假信息骗取账号名称注册，或账号头像、简介等注册信息存在违法和不良信息的，手机妈妈将暂停或注销。
        </div>
        <div class="mt10">
          2、请您妥善保管您注册时填写的用户帐号和密码，不要将帐号密码告知他人，因您原因导致帐号或密码泄露而造成的法律后果由用户负责。同时，您还应当对以此帐号登录进行的所有活动和事件承担全部后果与法律责任。
        </div>
        <div class="mt10">
          3、若您的帐号或密码遗失，可以通过注册信息按照相关步骤找回密码，若您发现账号遭到未授权的使用或存在其他安全漏洞的情况，应立即告知我公司。
        </div>
        <div class="text-bold mt10">三、用户个人信息</div>
        <div class="mt10">1、个人信息的保护</div>
        <div class="mt10">
          (1)保护用户个人信息及个人隐私是手机妈妈的基本原则之一。
        </div>
        <div class="mt3">
          (2)您在注册帐号或使用手机妈妈服务的过程中，需要填写一些必要的信息。若国家法律法规有特殊规定的，您可能还需要填写真实的身份信息。若您填写的信息不完整，可能无法注册帐户，或在使用手机妈妈服务过程中受到限制。
        </div>
        <div class="mt3">
          (3)我公司将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我公司的服务时提供的其他个人信息，但出于安全性和身份识别（如找回密码服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
        </div>
        <div class="mt3">
          (4)我公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
        </div>
        <div class="mt3">
          (5)未经您的同意，我公司不会向我公司及关联公司以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。
        </div>
        <div class="mt3">
          (6)手机妈妈非常重视对未成年人个人信息的保护，若未成年人（尤其是十岁以下）希望得以使用手机妈妈提供的服务，必须得到父母（监护人）的同意。
        </div>
        <div class="mt10">
          (7)我公司将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，保证不对外公开或向第三方披露或提供用户注册资料及用户在使用网络服务时存储在手机妈妈平台、服务器或数据库的非公开内容和信息，但下列情况除外：
        </div>
        <div class="mt10">1) 事先获得用户的授权； </div>
        <div class="mt3">2) 根据有关的法律法规要求； </div>
        <div class="mt3">3)按照相关政府主管部门和司法机关的要求； </div>
        <div class="mt3">4) 为维护社会公众的利益； </div>
        <div class="mt3">5)为维护手机妈妈的合法权益。 </div>
        <div class="mt10">2、个人信息的收集</div>
        <div class="mt10">
          我公司在提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我公司的用户或无法享受我公司提供的某些服务，或者无法达到相关服务拟达到的效果。
        </div>
        <div class="mt10">(1) 您提供的信息</div>
        <div class="mt10">
          您在注册帐户或使用手机妈妈的服务时，向我公司提供的相关个人信息，包括但不限于真实姓名、证件信息、电话号码、地址、电子邮件等；
        </div>
        <div class="mt10">
          您通过手机妈妈的服务向其他方提供的共享信息，以及您使用我公司的服务时所储存的信息。
        </div>
        <div class="mt10">(2)我公司获取的您的信息</div>
        <div class="mt10">
          您使用手机妈妈服务时我公司可能收集如下信息：使用手机妈妈服务时，系统可能通过cookies、web
          beacon或其他方式自动采集的技术信息，包括： 1)
          设备或软件信息，包括但不限于您的移动设备、网页浏览器或用于接入我公司服务的其他程序所提供的配置信息、您的IP地址、位置信息、设备存储和移动设备所用的版本和设备识别码；
          2)
          在使用我公司服务时搜索或浏览的信息，包括但不限于您浏览过的文章、搜索的关键词，以及您在使用我公司服务时浏览或要求提供的其他信息和内容详情；
          3)
          有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息。
        </div>
        <div class="mt10">3、个人信息的使用</div>
        <div class="mt10">
          (1)我公司可能将在向您提供服务的过程之中所收集的信息用作下列用途：
        </div>
        <div class="mt10">向您提供服务；</div>
        <div class="mt10">
          在我公司提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我公司向您提供的产品和服务的安全性；
        </div>
        <div class="mt10">帮助我公司设计新服务，改善我公司现有服务；</div>
        <div class="mt10">
          使我公司更加了解您如何接入和使用我公司的服务，从而针对性地回应您的个性化需求，例如个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
        </div>
        <div class="mt10">
          软件认证或管理软件升级； 让您参与有关我公司产品和服务的调查。
        </div>
        <div class="mt10">
          (2)为了让您有更好的体验，也为了改善我公司的服务，在符合相关法律法规的前提下，我公司可能将通过某一项服务收集信息或向第三方提供用户的非个人隐私信息的方式，用于我公司的其他服务。例如，在您使用我公司的一项服务时所收集的信息，可能会用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。
        </div>
        <div class="mt10">4、隐私政策</div>
        <div class="mt10">
          我公司非常重视您的个人信息保护，更多关于您个人信息保护的内容，请参见《隐私政策》。
        </div>
        <div class="text-bold mt10">四、知识产权 </div>
        <div class="mt10">一）关于手机妈妈</div>
        <div class="mt10">
          1、手机妈妈在全世界范围内的全部知识产权归我公司独家永久享有，包括但不限于手机妈妈相关的文字表述及其组合、图标、图饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等均为我公司所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。
        </div>
        <div class="mt10">
          2、手机妈妈或者提供服务中所涉及的图形、视频、文字或其组成，以及其他我公司标志及产品、服务名称，均为我公司之知识产权。未经我公司另行事先书面同意，您不得以营利为目的将我公司标识以任何方式展示或使用或作其他处理，也不得向他人表明有权展示、使用或者其他有权处理我公司标识的行为。
        </div>
        <div class="mt10">
          3、未经我公司另行书面同意，您不得以任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权，我公司保留追究上述未经许可行为的权利。
        </div>
        <div class="mt10">
          4、未经我公司另行书面同意，您不得对手机妈妈进行反向工程（Reverse
          Engineer）、反向编译（Decompile）或反向汇编（Disassemble），同时不得改动编译在程序文件内部的任何资源。
        </div>
        <div class="mt10">
          5、手机妈妈是作为一个整体产品而被授予许可使用,您不得将各个部分分开用于任何目的。
        </div>
        <div class="mt10">
          6、如需进行商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等，您必须另行取得我公司的书面授权和许可。
        </div>
        <div class="mt10">
          7、本协议未明示授权的其他一切权利仍归我公司所有，您使用其他权利时必须另行取得我公司的书面同意。
        </div>

        <div class="text-bold mt10">五、违法行为</div>
        <div class="mt10">
          1、您在使用手机妈妈时须遵守法律法规，不得利用手机妈妈从事违法违规行为，以及不得利用手机妈妈服务制作、上载、复制、发布、传播或者转载如下内容，包括但不限于：
        </div>
        <div class="mt10">(1) 反对宪法所确定的基本原则的；</div>
        <div class="mt3">
          (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </div>
        <div class="mt3">(3)损害国家荣誉和利益的；</div>
        <div class="mt3">(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</div>
        <div class="mt3">(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
        <div class="mt3">(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
        <div class="mt3">
          (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </div>
        <div class="mt3">(8)侮辱或者诽谤他人，侵害他人合法权益的；</div>
        <div class="mt3">(9)含有法律、行政法规禁止的其他内容的信息。</div>
        <div class="mt10">
          2、如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求手机妈妈给予协助。造成手机妈妈或第三方损害的，您应依法予以赔偿，手机妈妈不承担任何责任。
        </div>
        <div class="mt10">
          3、如果我公司发现或收到他人举报您发布的信息违反本条约定，我公司有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，我公司有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。
        </div>
        <div class="mt10">
          4、您违反本条约定，导致任何第三方损害的，您应当独立承担责任；我公司因此遭受损失的，您也应当一并赔偿（包括但不限于由此产生的赔偿金、诉讼费、调查取证费、律师费、差旅费）。
        </div>
        <div class="mt10">
          5、违反本条约定，视为您的严重违约，我公司可以中止对您的服务，解除本协议，且无需退还您所支付的费用（如有），视为您支付手机妈妈的违约金，如不足以弥补手机妈妈的损失的，手机妈妈还可通过其他法律途径向您追索。
        </div>
        <div class="text-bold mt10">六、投诉处理</div>
        <div class="mt10">
          1、我公司高度重视自由表达和正当权利的平衡，依照法律规定删除违法信息是手机妈妈社区的法定义务。
        </div>
        <div class="mt10">
          2、手机妈妈受理社区内侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。
        </div>
        <div class="mt10">
          (1)涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；
        </div>
        <div class="mt3">
          (2)造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；
        </div>
        <div class="mt3">
          (3) 商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。
        </div>
        <div class="mt10">
          3、如果个人或单位发现手机妈妈上存在侵犯自身合法权益的内容，请与我公司取得联系（邮箱：postmaster@aixzu.com）。为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则不予受理。请使用以下格式（包括各条款的序号）：
        </div>
        <div class="mt10">
          (1)权利人对涉嫌侵权内容拥有商标权、著作权和/或其他依法可以行使权利的权属证明；如果举报人非权利人，请举报人提供代表企业进行举报的书面授权证明。
        </div>
        <div class="mt3">
          (2)充分、明确地描述侵犯了权利人合法权益的内容，提供涉嫌侵权内容在手机妈妈上的具体页面地址，指明涉嫌侵权内容中的哪些内容侵犯了上述列明的权利人的合法权益；
        </div>
        <div class="mt3">
          (3)权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件；
        </div>
        <div class="mt3">
          (4) 在侵权举报中加入如下关于举报内容真实性的声明：
        </div>
        <div class="mt10">我本人为所举报内容的合法权利人； </div>
        <div class="mt10">
          我举报的发布在手机妈妈社区中的内容侵犯了本人相应的合法权益；{' '}
        </div>
        <div class="mt10">
          如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任。
        </div>
        <div class="mt10">4、处理流程 </div>
        <div class="mt10">
          (1)您认同，我公司对于投诉信息是否属于平台监督范围有权独立做出判断，并非所有申请手机妈妈都必须受理。我公司受理的投诉，将在自收到举报邮件七个工作日内处理完毕并给出回复。处理期间，不提供任何电话、邮件及其他方式的查询服务。
        </div>
        <div class="mt3">
          (2)出现手机妈妈已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题手机妈妈无权也无法处理，因此相关申请不予受理。
        </div>
        <div class="mt3">
          (3)此为手机妈妈社区唯一的官方的侵权投诉渠道，暂不提供其他方式处理此业务。用户在手机妈妈的商业行为引发的法律纠纷，由交易双方自行处理，与我公司无关。
        </div>
        <div class="text-bold mt10">七、 不可抗力与免责 </div>
        <div class="mt10">
          1、您理解并同意，在使用手机妈妈服务的过程中，可能会遇到不可抗力等风险因素，使手机妈妈提供的服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等，以及社会事件如战争、动乱、政府行为等。出现上述情况时，手机妈妈将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失，手机妈妈在法律允许的范围内免责。
        </div>
        <div class="mt10">
          2、在法律允许的范围内，手机妈妈对以下情形导致的服务中断或受阻不承担责任：
        </div>
        <div class="mt10">
          (1) 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；{' '}
        </div>
        <div class="mt3">
          (2)用户或手机妈妈的电脑软件、系统、硬件和通信线路出现故障；{' '}
        </div>
        <div class="mt3">(3)用户操作不当；</div>
        <div class="mt3">
          (4) 用户通过非手机妈妈授权的方式使用手机妈妈服务；
        </div>
        <div class="mt3">(5)其他手机妈妈无法控制或合理预见的情形。</div>
        <div class="mt10">
          3、您理解并同意，在使用手机妈妈服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，手机妈妈不对非手机妈妈发布的任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
        </div>
        <div class="mt10">
          (1) 来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
        </div>
        <div class="mt3">
          (2)因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
        </div>
        <div class="mt3">(3) 其他因网络信息或用户行为引起的风险。</div>
        <div class="mt10">
          4、您理解并同意，手机妈妈提供的服务并非为某些特定目的（包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域）而设计，如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，手机妈妈不承担法律责任。
        </div>
        <div class="mt10">
          5、我公司依据本协议约定获得处理违法违规内容的权利，该权利不构成手机妈妈的义务或承诺，手机妈妈不能保证及时发现违法行为或进行相应处理。
        </div>
        <div class="mt10">
          6、在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意手机妈妈或其他部门发布的有关防范诈骗犯罪的提示。
        </div>
        <div class="mt10">
          7、用户在手机妈妈发表的内容仅表明其个人的立场和观点，并不代表手机妈妈的立场或观点。作为内容的发表者，用户需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律责任；作为读者，用户应当自行对所看到的文章等内容进行甄别，并承担依照该等内容操作所带来的一切后果。手机妈妈不承担任何法律及连带责任。
        </div>
        <div class="text-bold mt10">八、协议的生效与修改</div>
        <div class="mt10">
          1、您进入手机妈妈并使用手机妈妈的服务即视为您已阅读本协议并接受本协议的约束。
        </div>
        <div class="mt10">
          2、手机妈妈有权在必要时修改本协议条款，您可以在相关服务页面查阅最新版本的协议条款。
        </div>
        <div class="mt10">
          3、本协议条款变更后，如果您继续使用手机妈妈提供的服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当退出手机妈妈并停止使用手机妈妈提供的服务。
        </div>
        <div class="text-bold mt10">九、 服务的中止与终止</div>
        <div class="mt10">
          1、手机妈妈可能会对提供的服务内容进行不定时的变更，也可能会中断、中止或终止服务。
        </div>
        <div class="mt10">
          2、如发生下列任何一种情形，手机妈妈有权不经通知而中断或终止向您提供的服务：
        </div>
        <div class="mt10">
          (1)根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
        </div>
        <div class="mt3">(2) 您涉嫌违反相关法律法规规定； </div>
        <div class="mt3">(3) 按照法律规定或主管部门的要求；</div>
        <div class="mt3">(4)出于安全的原因或其他必要的情形；</div>
        <div class="mt3">(5) 本协议中明确约定的其他情形。</div>
        <div class="text-bold mt10">十、 法律适用与争议解决 </div>
        <div class="mt10">1、 本协议签订地为中华人民共和国金华市义乌市。</div>
        <div class="mt10">
          2、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（仅为本合同之目的，不包括香港、台湾、澳门地区）。
        </div>
        <div class="mt10">
          3、若您和公司就本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方应将纠纷或争议提交本协议签订地人民法院管辖。
        </div>
        <div class="mt10">
          4、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        </div>
        <div class="mt10">
          5、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </div>

        <div class="text-bold mt10">十一、其他 </div>
        <div class="mt10">
          1、手机妈妈将根据平台功能区分或其他需要制订其他子协议，如子协议约定与本协议不一致，以子协议约定为准；子协议未涉及的事宜，仍以本协议为准。
        </div>
        <div class="mt10">
          2、如果您对本协议或手机妈妈有意见或建议，可与手机妈妈客服联系，我公司会给予您必要的帮助。
        </div>
      </div>
    );
  }
}

export default index;
