import React, {
  Component
} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import './pages/index.css';
import routes from './router/index';

export default class App extends Component {
  render() {
    console.log(this);
    return ( <
      Router >
      <
      Switch > {
        routes.map((item, index) => {
          if (item.exact) {
            if (item.redirect) {
              return ( <
                Redirect exact key = {
                  item.path
                }
                from = {
                  item.path
                }
                to = {
                  item.redirect
                }
                />
              );
            } else {
              return ( <
                Route key = {
                  item.path
                }
                exact path = {
                  item.path
                }
                //component={item.component}
                //路由嵌套父子组件传值
                render = {
                  (props) => {
                    return ( <
                      item.component {
                        ...props
                      }
                      routes = {
                        item.children
                      }
                      />
                    );
                  }
                }
                />
              );
            }
          } else {
            return ( <
              Route key = {
                item.path
              }
              path = {
                item.path
              }
              //component={item.component}
              //路由嵌套父子组件传值
              render = {
                (props) => {
                  return <item.component {
                    ...props
                  }
                  routes = {
                    item.children
                  }
                  />;
                }
              }
              />
            );
          }
        })
      } <
      /Switch> < /
      Router >
    );
  }
}